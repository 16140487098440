<template lang='pug'>
  div
    .font-bold.text-xl Управление посылкой
    p Будьте аккуратны во время редактирования данных, обратной дороги в этом процессе нет
    
    //p.bg-gray-300.px-3.rounded-md.py-3 {{ parcelInfo }}

    template(v-if='parcelInfo')
      .flex.mt-4
        div(style='width: 275px;')
          .font-bold.text-xl История статусов
          template(v-for='(value, index) in parcelInfo.history')
            div.mb-2.rounded-lg(:class='index == 0 ? "bg-green-500 text-white px-3 py-1" : "bg-gray-400 text-white px-3 py-1"')
              p.leading-tight {{ value.status }}
              .text-xs {{ moment(value.date).format('DD.MM.YY HH:mm') }}
          template(v-if='parcelInfo.history.length == 0')
            .text-xs.pl-2 Не найдено статусов заказа

          button.bg-green-500.text-white.mt-2(@click='addStatusStart') Добавить статус
        div.pl-4(style='width: 300px;')
          .font-bold.text-xl Общая информация

          template(v-if='isForward')
            .flex.flex-col.gap-2
              div
                .text-sm Ссылка на магазин
                input(v-model='parcelInfo.fwInfo.market_link')
              div
                .text-sm Название заказа
                input(v-model='parcelInfo.fwInfo.order_name')
              div
                .text-sm Трек номер
                input(v-model='parcelInfo.fwInfo.track_number')
              div
                .text-sm Цена
                input(v-model='parcelInfo.fwInfo.product_price')
              div
                .text-sm Валюта
                input(v-model='parcelInfo.fwInfo.product_currency')
              div
                .text-sm Список заказанных услуг
                .text-xs.pl-2 Доп. услуг заказано не было
          template(v-else)
             p Заказ на покупку
    template(v-else)
      p Идёт загрузка данных

    .mt-4.flex.justify-end
      button.pl-2(@click='discard()') Отменить 
      button.bg-green-500.text-white(@click='save()') Сохранить
</template>

<script>
import { getParcels, editParcel } from '../assets/api.js';

export default {
  mounted() {
    this.loadData();
  },
  data() {
    return {
      parcelInfo: null,
    }
  },
  computed: {
    isForward() {
      return this.parcelInfo && this.parcelInfo.fwInfo;
    },
    currentParcelId() {
      return this.$route.params.parcelId;
    }
  },
  methods: {
    addStatusStart() {
      this.$root.openDialog({
        component: {
          is: () => import('./../components/dialogs/Statuses.vue'),
          onSave: this.addStatusFinish
        }
      });
    },

    addStatusFinish(status) {
      this.parcelInfo.history.unshift({
        status: status,
        date: Date.now()
      });
    },

    async loadData() {
      const result = await getParcels(this.currentParcelId);
      if (!result.success) {
        console.error('Не удалось загрузить данные склада');
        return;
      }

      this.parcelInfo = result.data;
    },
    async save() {
      const result = await editParcel(this.parcelInfo);
      if (!result.success) {
        console.error('Не удалось загрузить данные склада');
        return;
      }

      this.parcelInfo = result.data;
    }
  }
}
</script>

<style>

</style>